import http from "@/core/http-login";

export async function loginService(username, userpassword) {
    const body = {
        username,
        userpassword
    }
    const response = await http.post("/login", body, {
        headers: {
            appid: localStorage.getItem('appId')
        }
    });
    console.log("🚀 ~ loginService ~ response:", response.data);
    return response
}

export function saveAppId(appId) {
    localStorage.setItem('appId', appId);
}

export function getAppId() {
    return localStorage.getItem('appId');
}

export function saveToken(token) {
    let grant = {
        Token: token
    }
    localStorage.setItem('grant', JSON.stringify(grant));
}

export function getToken() {
    const token = JSON.parse(localStorage.getItem('grant'))?.Token;
    return token
}

export function logout() {
    localStorage.removeItem('grant');
}

export function isAuth() {
    const token = getToken();
    return token !== null && token !== undefined;
}