export function formatLanguageData(data) {
    let messages = {}
    const languages = data.shift()

    for (let i = 1; i < languages.length; i++) {
        messages[languages[i]] = {}
        for (let j = 0; j < data.length; j++) {
            messages[languages[i]][data[j][0]] = data[j][i]
        }
    }
    return messages
}