function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ].join(',') : null;
}

export function overwriteBaseColors(primaryColor) {
  const style = document.createElement('style');

  style.innerHTML = `
    .btn-primary {
      --bs-btn-bg: ${primaryColor};
      --bs-btn-border-color: ${primaryColor};
      --bs-btn-hover-bg: ${primaryColor};
      --bs-btn-hover-border-color: ${primaryColor};
      --bs-btn-active-bg: ${primaryColor};
      --bs-btn-active-border-color: ${primaryColor};
      --bs-btn-disabled-bg: ${primaryColor};
      --bs-btn-disabled-border-color: ${primaryColor};
    }

    .btn-outline-primary {
      --bs-btn-color: ${primaryColor};
      --bs-btn-border-color: ${primaryColor};
      --bs-btn-hover-color: #fff;
      --bs-btn-hover-bg: ${primaryColor};
      --bs-btn-hover-border-color: ${primaryColor};
      --bs-btn-focus-shadow-rgb: 13, 110, 253;
      --bs-btn-active-color: #fff;
      --bs-btn-active-bg: ${primaryColor};
      --bs-btn-active-border-color: ${primaryColor};
      --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      --bs-btn-disabled-color: ${primaryColor};
      --bs-btn-disabled-bg: transparent;
      --bs-btn-disabled-border-color: ${primaryColor};
      --bs-gradient: none;
  }

    :root {
      --bs-primary: ${primaryColor};
      --bs-primary-rgb: ${hexToRgb(primaryColor)};
    }

    .form-check-input:checked {
      background-color: ${primaryColor};
      border-color: ${primaryColor};
    }
  }
  
  @media (prefers-color-scheme: dark) {
    :root {}
  }`;

  document.head.append(style);
}