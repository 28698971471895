import axios from 'axios';

const {
    VUE_APP_GOOGLE_SHEETS_API,
    VUE_APP_GOOGLE_SHEETS_API_KEY,
    VUE_APP_GOOGLE_SHEETS_ID
} = process.env

export async function getLanguagesData() {
    const response = await axios
        .get(`${VUE_APP_GOOGLE_SHEETS_API}/v4/spreadsheets/${VUE_APP_GOOGLE_SHEETS_ID}/values/Translations!A:ZZ?key=${VUE_APP_GOOGLE_SHEETS_API_KEY}`);
    return response;
}