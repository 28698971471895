import { createI18n } from "vue-i18n";
import { getLanguagesData } from "@/services/Google";
import { formatLanguageData } from "@/helpers/formatData";

let messages = {}
try {
    const response = await getLanguagesData()
    const { values } = response.data
    values.shift()
    messages = formatLanguageData(values)
} catch (error) {
    console.log("🚀 ~ error:", error)
}

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    globalInjection: true,
    messages,
});

export default i18n;