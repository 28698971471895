import { isAuth } from '@/services/Auth';
import { createRouter, createWebHistory } from 'vue-router'; // Importa createRouter y createWebHistory

const routes = [
  {
    path: "/",
    component: () => import('../views/home/indexHome.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Home',
        // redirect:'/search-user',
        component: () => import('../views/HomePage.vue'),
      },
      // {
      //   path: 'platform-updates',
      //   name: 'PlatformUpdates',
      //   component: () => import('../views/PlatformUpdatesPage.vue'),
      // },
      {
        path: "agenda",
        name: "Agenda",
        component: () => import('../views/AgendaPage.vue'),
      },
      {
        path: "notifications",
        name: "Notifications",
        component: () => import('../views/NotificationsPage.vue'),
      },
      {
        path: "search-user",
        name: "SearchUser",
        component: () => import('../views/SearchUserPage.vue'),
      },
      {
        path: "reports",
        name: "Reports",
        component: () => import('../views/ReportsPage.vue'),
      },
      /*{
        path: "connections",
        name: "Connections",
        component: () => import('../views/ConnectionsPage.vue'),
      },*/
      {
        path: "settings",
        name: "Settings",
        component: () => import('../views/SettingsPage.vue'),
      },
      {
        path: "logs",
        name: "Logs",
        component: () => import('../views/LogsPage.vue'),
      },
      {
        path: "/clinic-cloud-plan",
        name: "UserPerfil",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/personal-information",
        name: "PersonalInformation",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/appointment-type",
        name: "AppointmentType",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/availability",
        name: "YourAvailability",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/general-settings/telemedicine",
        name: "Telemedicine",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/general-settings/calendar",
        name: "Calendar",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/general-settings/medical-record",
        name: "MedicalRecord",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/manage-users",
        name: "ManageUsers",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/subaccounts",
        name: "SubaccountsUser",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/notification-preferences",
        name: "NotificationPreferences",
        component: () => import('../views/UserPerfilPage.vue'),
      },
      {
        path: "/billing",
        name: "Billing",
        component: () => import('../views/BillingPage.vue'),
      },
      {
        path: "/payment-history",
        name: "PaymentHistory",
        component: () => import('../views/BillingPage.vue'),
      },
      {
        path: "/recurring-items",
        name: "RecurringItems",
        component: () => import('../views/BillingPage.vue'),
      },
      {
        path: "/payment-setting/add-payment-method",
        name: "PaymentMethod",
        component: () => import('../views/BillingPage.vue'),
      },
      {
        path: "/payment-setting/payment-information",
        name: "PaymentSettingComponent",
        component: () => import('../views/BillingPage.vue'),
      },
      {
        path: "/pricing",
        name: "Pricing",
        component: () => import('../views/BillingPage.vue'),
      },
      {
        path: "/preferences",
        name: "Preferences",
        component: () => import('../views/BillingPage.vue'),
      },
      {
        path: "/usage-summary",
        name: "UsageSummary",
        component: () => import('../views/BillingPage.vue'),
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import('../views/LoginPage.vue'),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import('../views/RegisterPage.vue'),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import('../views/ForgotPasswordPage.vue'),
  },
  {
    path: "/two-factor-authentication",
    name: "TwoFA",
    component: () => import('../views/TwoFAPage.vue'),
  },
  {
    path: "/selecting-profile",
    name: "SelectProfile",
    component: () => import('../views/SelectProfilePage.vue'),
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: () => import('../views/SignUpPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const routesRequiringUser = [
  'Notifications',
  'Logs',
  'Agenda',
]

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuth()) {
    next('/login');
    return
  } else {
    next();
  }
  if (this.$store.state.userInfo && routesRequiringUser.includes(to.name)) {
    next('/search-user');
    return
  }
});

export default router;