import axios from "axios";

const http = axios.create({
  baseURL: process.env.VUE_APP_BOPS_CLOUD_API_URL,
  headers: {
    'appid': localStorage.getItem('appId'),
  }
});

http.interceptors.request.use(
  function (config) {
    if (localStorage.getItem('grant')) {
      config.headers.Authorization = 'Bearer ' + (JSON.parse(localStorage.getItem('grant'))).Token;
      config.headers.appid = localStorage.getItem('appId')
    }
    return config;
  });

export default http;